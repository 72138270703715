import react from "react";
import { Richtext } from '@YannickTM/ui';

export default function RichTextModule(entry) {
  const result = (
    <div key="richTextModule">
      {entry.title && (
        <>
          <h1>{entry?.title}</h1>
        </>
      )}
      <Richtext document={entry.content} />
    </div>
  );

  return result;
}
