import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import JSONPretty from "react-json-pretty";

import dynamic from "next/dynamic";

export default function MapsModule(entry) {
  const introText =
    entry.introText !== undefined
      ? documentToReactComponents(entry.introText)
      : null;
  const locations = entry?.locations || null;

  //console.log(locations);

  const MapWithNoSSR = dynamic(() => import("../components/maps-component"), {
    loading: () => <p>...</p>,
    ssr: false,
  });

  const result = (
    <div key={`mapsModule_${Math.random()}`}>
      {entry.title && (
        <>
          <h1>{entry?.title}</h1>
        </>
      )}
      {introText !== null && <>{introText}</>}
      <MapWithNoSSR center={entry.center} locations={locations} />
    </div>
  );

  return result;
}
