import MaintenanceStage from "./stage-maintenance";
import MapsModule from "./module-maps";
import JSONPretty from "react-json-pretty";

export default function CMSStageModuleRenderer(entry) {
  switch (entry?.contentTypeId) {
    case "ccMaintenanceStage":
      return (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "#fff",
            color: "#000",
          }}
          id="maintenanceStage"
        >
          {MaintenanceStage(entry)}
        </div>
      );
      break;
    case "mapsModule":
      return <div key={i}>{MapsModule(entry)}</div>;
      break;
    default:
      const result = { INFO: "UNIMPLEMENTED MODULE", ...entry };
      return (
        <div key="StageModule">
          <JSONPretty
            key={`unimplmentedModule_${Math.random()}`}
            id="json-pretty"
            data={result}
            style={{ overflow: "hidden" }}
          ></JSONPretty>
        </div>
      );
  }
}
