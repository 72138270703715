import Alert from "../components/alert";
import Footer from "../components/footer";
import Header from "../components/header";
import Meta from "../components/meta";
import Container from "../components/container";
import BurgerMenu from "../components/burger-menu";

export default function Layout({
  preview,
  footer = "Off",
  header = "Off",
  navigation = "Off",
  navigationData = {},
  layout = "default",
  children,
}) {
  return (
    <>
      <Meta />
      <Alert preview={preview} />
      {(navigation === "Burger" || navigation === "Full") && (
        <BurgerMenu navigation={navigationData} />
      )}
      <div className="min-h-screen">
        <main id="page-wrap">
          {header === "Full" && (
            <Container>
              <Header navigation={navigationData} />
            </Container>
          )}
          {header === "Minimal" && (
            <Container>
              <Header />
            </Container>
          )}
          {children}
        </main>
      </div>
      {footer === "Full" && <Footer />}
      {footer === "Minimal" && <Footer />}
    </>
  );
}
