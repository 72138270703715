import Link from "next/link";
import { push as Menu } from "react-burger-menu";

const menuStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#ffffff", //'#373a47'
  },
  bmBurgerBarsHover: {
    background: "#ffffff", //"#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#ffffff", //'#bdc3c7'
  },
  bmMenuWrap: {
    position: "fixed",
    top: 0,
    height: "100%",
  },
  bmMenu: {
    background: "#2c2c2c",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#ffffff", //'#b8b7ad',
    padding: "0.8em",
  },
  bmItem: {
    display: "block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

export default function BurgerMenu(navigation) {
  //console.log(navigation);

  return (
    <Menu
      pageWrapId={"page-wrap"}
      outerContainerId={"app-container"}
      styles={menuStyles}
    >
      {navigation.navigation
        .filter((e) => e.title !== undefined)
        .map((e) => (
          <a
            key={e.title.toLowerCase().replace(" ", "-")}
            id={e.title.toLowerCase().replace(" ", "-")}
            className="menu-item"
            target={e.target}
            href={e?.src === undefined ? e.url : e.src}
          >
            {e.title}
          </a>
        ))}
    </Menu>
  );
}
