import React from "react";
import PageTitle from "./page-title";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "./container";

export default function MaintenanceStage(entry) {
  const body =
    entry?.body !== undefined ? documentToReactComponents(entry?.body) : null;
  const result = (
    <div key={`maintenanceStage_${Math.random()}`}>
      {entry && (
        <>
          <Container>
            <div className={"grid place-items-center h-screen"}>
              <div>
                {entry?.title}
                {body !== null && <>{body}</>}
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );

  return result;
}
