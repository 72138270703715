import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import JSONPretty from "react-json-pretty";
import Image from "next/image";

export default function MediaModule(entry) {
  switch (entry.type) {
    case "youtube":
      return (
        <div key={`mediaModule_${Math.random()}`} className="embed-container">
          <iframe
            title={entry.title}
            width="640"
            //height="100%"
            src={`https://www.youtube.com/embed/${entry.video.videoId}`}
            //frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
      break;
    case "vimeo":
      return (
        <div key={`mediaModule_${Math.random()}`} className="embed-container">
          <iframe
            title={entry.title}
            src={`https://player.vimeo.com/video/${entry.video.videoId}`}
            width="640"
            //height="100%"
            //frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
      break;
    default:
      entry = Object.assign({ INFO: "UNSUPPORTED MEDIA TYPE" }, entry);
      return (
        <JSONPretty
          id="json-pretty"
          data={entry}
          style={{ overflow: "hidden" }}
        ></JSONPretty>
      );
  }
}
