//import ContentfulImage from "./contentful-image";
import RichTextModule from "./module-rich-text";
import FormsModule from "./module-forms";
import { Form } from "@YannickTM/ui";
import MediaModule from "./module-media";
import MapsModule from "./module-maps";
import JSONPretty from "react-json-pretty";

export default function CMSContentModuleRenderer(entry, i) {
  switch (entry?.contentTypeId) {
    case "richTextModule":
      return <div key={i}>{RichTextModule(entry)}</div>;
      break;
    case "formsModule":
      return (
        <div key={i}>
          <Form
            title={entry?.title}
            formUrl={entry.formUrl}
            introText={entry.introText}
          />
        </div>
      );
      break;
    case "mediaModule":
      return <div key={i}>{MediaModule(entry)}</div>;
      break;
    case "mapsModule":
      return <div key={i}>{MapsModule(entry)}</div>;
      break;
    default:
      const result = { INFO: "UNIMPLEMENTED MODULE", ...entry };
      return (
        <div key={i}>
          <JSONPretty
            key={`unimplmentedModule_${Math.random()}`}
            id="json-pretty"
            data={result}
            style={{ overflow: "hidden" }}
          ></JSONPretty>
        </div>
      );
  }
}
