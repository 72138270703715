import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Widget } from "@typeform/embed-react";
import { Form } from "@YannickTM/ui";

export default function FormsModule(entry) {
  if (!entry?.formUrl) return null;


  const result = (
    <Form title={entry?.title} formUrl={entry.formUrl} introText={entry.introText} />
  );

  return result;
}
